import { setUserGroup, trackEvent } from "@/utils/analyticsFunctions";
import Button from "../buttons/Button";
import Spinner from "../misc/Spinner";
import { getLatestRouteBeforeLogin } from "../layout";
import TextInput from "../inputs/TextInput";
import { useState } from "react";
import { useRouter } from "next/router";
import { useAuth } from "@/auth";
import { toast } from "react-toastify";
import {
  captureException,
  devPrint,
  getErrorFromResponse,
} from "@/utils/devUtils";
import { _ } from "@/pages/_app";

interface ISignupComponent {
  onSignedUp: () => void;
}
const SignupComponent = (props: ISignupComponent) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [forgotPass, setForgotPass] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");

  const { loading, isAuthenticated, login, signup } = useAuth();

  const handleSignup = async () => {
    if (name === "") {
      toast("Name cannot be empty", { type: "error" });
      return;
    }
    setIsLoading(true);
    try {
      const res: Response = await signup(email, password, name);
      if (res.status > 299) {
        var msg = await getErrorFromResponse(res);
        devPrint("msg", msg);
        toast.error("Error signing up with\n" + msg);
        setIsLoading(false);
      } else {
        trackEvent("signup", { type: "email" });
        setUserGroup("auth", "email");
        props.onSignedUp();
      }
    } catch (e) {
      captureException(e, true);
    }
    setIsLoading(false);
  };

  return (
    <form
      className=" pt-2 pb-1 mb-4"
      onSubmit={(e) => {
        e.preventDefault();
        if (loading) {
          return;
        }
        handleSignup();
      }}
    >
      <TextInput
        placeholder="Name"
        onChange={(val) => {
          setName(val);
        }}
      />
      <TextInput
        placeholder="Email"
        onChange={(val) => {
          setEmail(val);
        }}
      />
      <TextInput
        type="password"
        placeholder="Password"
        onChange={(val) => {
          setPassword(val);
        }}
      />
      <input type="submit" hidden />
      <div className="f">
        {isLoading ? (
          <div className="flex justify-center">
            <Spinner />
          </div>
        ) : (
          <Button
            onClick={() => {
              handleSignup();
            }}
          >
            {_("Signup")}
          </Button>
        )}
      </div>
    </form>
  );
};

export default SignupComponent;
