import Button from "@/components/buttons/Button";
import CheckoutLayout from "@/components/checkoutLayout";
import TextInput from "@/components/inputs/TextInput";
import { getLatestRouteBeforeLogin } from "@/components/layout";
import SignupComponent from "@/components/login/SignupComponent";
import Spinner from "@/components/misc/Spinner";
import { User } from "@/openapi";
import { IStore } from "@/redux/store";
import { stripeApi } from "@/utils/NetworkUtils";
import { setUserGroup, trackEvent } from "@/utils/analyticsFunctions";
import { getAppleClientId, getAppleLoginRedirectURI } from "@/utils/appleLogin";
import {
  captureException,
  devPrint,
  getErrorFromResponse,
  isLocalhost,
} from "@/utils/devUtils";
import {
  IStripeProductPrice,
  durationToReadable,
  getPaymentReturnCancelUrl,
  getPaymentReturnSuccessUrl,
  getProductTitle,
  getStripePublishableKey,
  priceToReadable,
} from "@/utils/paymentUtils";
import { getLocalUser } from "@/utils/sharedPrefrences";
import AppleIcon from "@mui/icons-material/Apple";
import Google from "@mui/icons-material/Google";
import { useGoogleLogin } from "@react-oauth/google";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Image from "next/image";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import AppleLogin from "react-apple-login";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useAuth } from "../auth";
import { _ } from "./_app";
import Checkout from "./checkout";

interface EmailLoginProps {
  onBack: () => any;
  onLoggedIn: () => void;
}

const EmailLogin = (props: EmailLoginProps) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [forgotPass, setForgotPass] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { loading, isAuthenticated, login } = useAuth();
  const router = useRouter();

  const handleLogin = async () => {
    setIsLoading(true);
    trackEvent("login", { type: "email" });
    try {
      var res: Response = await login(email, password);
      if (res.status > 299) {
        var msg = await getErrorFromResponse(res);
        toast.error("Error logging in\n" + msg);
        setIsLoading(false);
        return;
      }
      setUserGroup("auth", "email");
      props.onLoggedIn();
    } catch (e) {
      console.log("error logging in", e);
    }
    setIsLoading(false);
  };

  return (
    <form
      className=" pt-2 pb-2 mb-4"
      onSubmit={(e) => {
        e.preventDefault();
        if (loading) {
          return;
        }
        handleLogin();
      }}
    >
      <TextInput
        placeholder="Email"
        onChange={(val) => {
          setEmail(val);
        }}
      />
      <TextInput
        type="password"
        placeholder="Password"
        onChange={(val) => {
          setPassword(val);
        }}
      />
      <input type="submit" hidden />
      <div className="f">
        {isLoading ? (
          <div className="flex justify-center">
            <Spinner />
          </div>
        ) : (
          <Button
            onClick={() => {
              handleLogin();
            }}
          >
            {_("Login")}
          </Button>
        )}
        <div
          className={
            "cursor-pointer justify-center m-auto flex " +
            (forgotPass ? "" : "underline")
          }
          onClick={() => {
            setForgotPass(true);
          }}
        >
          {forgotPass
            ? _("You can reset your password inside the app")
            : _("Forgot password?")}
        </div>
      </div>
    </form>
  );
};

interface ILogin {
  product: IStripeProductPrice | undefined;
  user: User | undefined;
}

const Login = ({ product, ...props }: ILogin) => {
  const [showEmail, setShowEmail] = useState(false);
  const router = useRouter();
  const [showApple, setShowApple] = useState(false);

  const [showSignup, setShowSignup] = useState(false);

  const {
    loading,
    isAuthenticated,

    loginWithGoogle,
    loginWithApple,
  } = useAuth();

  const stripe = useStripe();

  useEffect(() => {
    if (!loading && isAuthenticated && !product) {
      router.replace("/checkout");
    }
  }, [isAuthenticated, loading, product]);

  useEffect(() => {
    // not sure if this is needed
    const script = document.createElement("script");
    script.src =
      "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";
    script.async = true;
    script.onload = () => {
      setShowApple(true);
    };
    document.body.appendChild(script);

    // weird bug where the script isn't loaded
    setTimeout(() => {
      setShowApple(true);
    }, 1000);
  }, []);

  const gLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      trackEvent("login", { type: "google" });
      loginWithGoogle(tokenResponse.access_token, tokenResponse.access_token)
        .then(async (res: Response) => {
          if (res.status > 299) {
            trackEvent("login_error", { type: "google" });
            var msg = await getErrorFromResponse(res);
            toast.error(msg);
            return;
          }
          setUserGroup("auth", "google");
          handleNavAfterLogin();
        })
        .catch((value: any) => {
          devPrint("EEEEE", value);
          trackEvent("login_error", { type: "google" });
          captureException(value, true);
          toast.error("Error logging in via google: " + value);
        });
    },
  });

  const renderAppleLoginBtn = () => {
    var btnText = showSignup ? _("Sign up with Apple") : _("Login with Apple");

    return (
      <AppleLogin
        usePopup={showApple}
        clientId={getAppleClientId()}
        redirectURI={getAppleLoginRedirectURI()}
        callback={(e) => {
          const code = e.authorization.code;
          const idToken = e.authorization.id_token;
          trackEvent("login", { type: "apple" });
          loginWithApple(code, idToken)
            .then(async (res: Response) => {
              if (res.status > 299) {
                trackEvent("login_error", { type: "google" });
                var msg = await getErrorFromResponse(res);
                toast.error(msg);
                return;
              }
              setUserGroup("auth", "apple");
              handleNavAfterLogin();
            })
            .catch((value: any) => {
              captureException(value, true);
              trackEvent("login_error", { type: "apple" });
              toast.error("Error signing in with Apple " + value);
            });
        }}
        render={(_props) => {
          return (
            <Button
              icon={<AppleIcon className="text-primary-brown" />}
              className="mb-6  bg-white text-primary-brown "
              onClick={() => {
                if (isLocalhost()) {
                  toast("Apple login does not work on localhost");
                  return;
                }
                _props.onClick();
              }}
            >
              <span className="text-black"> {btnText}</span>
            </Button>
          );
        }}
      />
    );
  };

  const handleNavAfterLogin = async () => {
    var lUser = getLocalUser();

    if (product && stripe && lUser) {
      try {
        const res = await stripeApi.stripeCheckoutSessionCreate({
          cancelUrl: getPaymentReturnCancelUrl(product),
          successUrl: getPaymentReturnSuccessUrl(product),
          priceId: product.price.id,
          coupon: "",
          clientReferenceId: lUser?.id.toString(),
        });

        // When the customer clicks on the button, redirect
        // them to Checkout.
        stripe!
          .redirectToCheckout({
            sessionId: res.id,
          })
          .then(function (result) {
            if (result.error) {
              // If `redirectToCheckout` fails due to a browser or network
              // error, display the localized error message to your customer.
              // setErrorMessage(result.error.message);
              toast.error(result.error.message);
            }
          });
        return;
      } catch (e) {
        captureException(e, true);
      }
    }
    const r = getLatestRouteBeforeLogin();
    if (r) {
      router.push(r);
      return;
    }
    router.push("/");
  };

  const renderButtons = () => {
    return (
      <div
        key={Math.random()}
        className="  px-8 pt-24 pb-8 mb-4 m-auto text-pimary-brown"
        style={{ maxWidth: 450 }}
      >
        <div className="mb-8">
          <div style={{ fontSize: 32 }} className="font-semibold">
            {_("Create your account & elevate your training")}
          </div>
          <div>{_("Please enter your details")} </div>
        </div>
        {showSignup ? (
          <SignupComponent
            onSignedUp={() => {
              handleNavAfterLogin();
            }}
          />
        ) : (
          <EmailLogin
            onLoggedIn={() => {
              handleNavAfterLogin();
            }}
            onBack={() => {
              setShowEmail(false);
            }}
          />
        )}
        <Button
          icon={<Google className="text-black" />}
          className="mb-6 w-full shadow-md bg-white text-primary-brown"
          onClick={() => {
            gLogin();
          }}
        >
          <span className="text-primary-brown">
            {showSignup ? _("Sign up with Google") : _("Login with Google")}
          </span>
        </Button>
        {renderAppleLoginBtn()}
        <div
          onClick={() => {
            router.push("/login#signup");
            setShowSignup(!showSignup);
          }}
          className="text-xs mb-6 text-stone-gray cursor-pointer text-center"
        >
          {showSignup ? (
            <div>
              {_("Already have an account?")}{" "}
              <span className="underline">{_("Login")}</span>
            </div>
          ) : (
            <>
              {_("If you do not have an account, you can")}{" "}
              <span className="underline">{_("create one!")}</span>
            </>
          )}
        </div>
        <div className="text-xs mb-6 text-stone-gray cursor-pointer text-center">
          {_("login-page__terms-of-use-1")}{" "}
          <a
            href="https://www.horseday.com/terms-privacy"
            className="underline"
          >
            {_("login-page__terms-of-use-2")}
          </a>
        </div>
      </div>
    );
  };

  const renderSelectedProduct = () => {
    if (!product) {
      return null;
    }
    return (
      <div className="bg-surface p-8 pt-24 ">
        <div className="font-semibold" style={{ fontSize: 24 }}>
          {_("Checkout")}
        </div>
        <div>{_("Please login to continue with your checkout")}</div>

        <div className="mt-3 border-action bg-white p-2 rounded-md border-2">
          <div className="flex flex-row justify-between">
            <span>{getProductTitle(product!)}</span>
            <span>
              {priceToReadable(
                product.price.monthPrice,
                product.price.currency
              )}{" "}
              / <span className="font-thin">{durationToReadable("MONTH")}</span>
            </span>
          </div>
          {product.product.metadata.duration === "YEAR" && (
            <>
              <hr className="m-2" />
              <div className="flex flex-row justify-between">
                <span className="font-semibold">{_("Total after trial")}</span>
                <span>
                  {product.price.discount && (
                    <span className="mr-2 text-stone-gray">
                      {_("You save")}{" "}
                      {(product.price.discount * 100).toFixed(0)} %
                    </span>
                  )}
                  <span>
                    {priceToReadable(
                      product.price.unitAmount,
                      product.price.currency
                    )}{" "}
                    /{" "}
                    <span className="font-thin">
                      {durationToReadable(product.product.metadata.duration)}
                    </span>
                  </span>
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  const renderNotLoggedIn = () => {
    return (
      <div
        className="grid grid-cols-1 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 bg-eggshell  "
        style={{
          // maxWidth: 1800,
          minHeight: "100vh",
        }}
      >
        {!product ? (
          <div className="hidden 2xl:block xl:block lg:block md:block sm:hidden  ">
            <Image
              src="/loginphoto4.jpg"
              width={1440}
              height={2048}
              alt="image of horse"
              style={{
                objectFit: "cover",
                maxWidth: "auto",
                height: window.innerHeight - 72,
              }}
            />
          </div>
        ) : (
          renderSelectedProduct()
        )}
        <div>{renderButtons()}</div>
      </div>
    );
  };

  const renderLoggedIn = () => {
    return <Checkout />;
  };

  return (
    <CheckoutLayout>
      {isAuthenticated ? renderLoggedIn() : renderNotLoggedIn()}
    </CheckoutLayout>
  );
};

const LoginPageContainer = (props: ILogin) => {
  return (
    <Elements stripe={stripePromise}>
      <Login user={props.user} product={props.product} />
    </Elements>
  );
};

const stripePromise = loadStripe(getStripePublishableKey());

const mapStateToProps = (state: IStore) => {
  const data = state.paymentState;
  const { user } = state.userState;

  const { product } = data;
  return { product, user };
};

export default connect(mapStateToProps)(LoginPageContainer);
